export default $axios => ({
  resource: 'voicy',

  /**
   * Update news
   *
   * @returns {Object}
   */
  uploadVoicyImageContent(data, config = {}) {
    return $axios.post('voicy/image/upload', data, config)
  },

  /**
   * Update resource
   *
   * @param {Number} id
   * @param {Object} data
   * @param {Object} config
   * @returns {Object}
   */
  updateWithFile(id, data, config = {}) {
    return $axios.post(`voicy/${id}`, data, config)
  }
})
