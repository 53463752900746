/*
 * Copyright 2020 Digi Dinos JSC. All rights reserved.
 * Email: tech@digidinos.com.
 */

import CreateRepository from '~/repositories/service.container'

export default ({ $axios, app }, inject) => {
  $axios.onError(error => {
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 422)
    ) {
      app.router.push('/error')
    }

    return Promise.reject(error)
  })

  inject('api', CreateRepository($axios))
}
