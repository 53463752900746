import {
  SET_LOADING,
  SET_IS_PC,
  SET_IS_SIDEBAR_COLLAPSED,
  SET_VISIBLE_USER_OFFCANVAS_MENU
} from '~/constants/mutation-types'

export const state = () => ({
  loading: false,
  isPC: true,
  isSidebarCollapsed: false,
  visibleUserOffcanvasMenu: false,
  key: null
})

export const getters = {
  loading: state => state.loading,
  isPC: state => state.isPC,
  isSidebarCollapsed: state => state.isSidebarCollapsed,
  visibleUserOffcanvasMenu: state => state.visibleUserOffcanvasMenu
}

export const mutations = {
  [SET_LOADING]: (state, payload) => {
    state.loading = payload
  },
  [SET_IS_PC]: (state, payload) => {
    state.isPC = payload
  },
  [SET_IS_SIDEBAR_COLLAPSED]: (state, payload) => {
    state.isSidebarCollapsed = payload
  },
  [SET_VISIBLE_USER_OFFCANVAS_MENU]: (state, payload) => {
    state.visibleUserOffcanvasMenu = payload
  },
  setKey(state, key) {
    state.key = key
  }
}

export const actions = {
  /**
   * Set loading global
   *
   * @param {Function} commit
   * @param {Boolean} payload
   */
  setLoading({ commit }, payload) {
    commit(SET_LOADING, payload)
  },

  /**
   * Set visible user offcanvas menu
   *
   * @param {Function} commit
   * @param {Boolean} payload
   */
  setVisibleUserOffcanvasMenu({ commit }, payload) {
    commit(SET_VISIBLE_USER_OFFCANVAS_MENU, payload)
  }
}
