export default $axios => ({
  resource: 'user-answers',

  /**
   * Create answer
   *
   * @returns {Object}
   */
  saveAnswer(data, config = {}) {
    return $axios.post('home/user-answers/create', data, config)
  },

  /**
   * Show user answer
   *
   * @returns {Object}
   */
  showDataAnswer(code, config = {}) {
    return $axios.get(`home/user-answers/get-result/${code}`, config)
  },

  /**
   * Get image
   */
  getImage(config = {}) {
    return $axios.get(`home/user-answer/get-image`, config)
  }
})
