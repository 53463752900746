/*
 * Copyright 2020 Digi Dinos JSC. All rights reserved.
 * Email: tech@digidinos.com.
 */

import Vue from 'vue'
import VCalendar from 'v-calendar'

if (process.client) {
  Vue.use(VCalendar, {})
}
