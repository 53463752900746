export default $axios => ({
  resource: 'result',

  /**
   * Update resource
   *
   * @param {Number} id
   * @param {Object} data
   * @param {Object} config
   * @returns {Object}
   */
  update(id, data, config = {}) {
    return $axios.post(`${this.resource}/${id}`, data, config)
  },

  /**
   * Capture img
   */
  captureImg(data, config = {}) {
    return $axios.post('/home/result/capture-img', data)
  }
})
