import appsRepository from './apps.repository'
import catRepository from './cat.repository'
import characterRepository from './character.repository'
import keyRepository from './key.repository'
import questionsRepository from './questions.repository'
import resultRepository from './result.repository'
import RoleRepository from './role.repository'
import surveysRepository from './surveys.repository'
import userAnswersRepository from './userAnswers.repository'
import voicyRepository from './voicy.repository'

/**
 * Make repository
 *
 * @param {Object} $axios
 * @param {String} resource
 * @returns {Object}
 */
const make = ($axios, resource) => ({
  resource,

  /**
   * Get list resources
   *
   * @param {Object} config
   * @returns {Object}
   */
  list(config = {}) {
    return $axios.get(`home/${this.resource}`, config)
  },

  /**
   * Create resource
   *
   * @param {Object} data
   * @param {Object} config
   * @returns {Object}
   */
  create(data, config = {}) {
    return $axios.post(`${this.resource}`, data, config)
  },

  /**
   * Show detail resource
   *
   * @param {Number} id
   * @param {Object} config
   * @returns {Object}
   */
  show(id, config = {}) {
    return $axios.get(`home/${this.resource}/${id}`, config)
  },

  /**
   * Update resource
   *
   * @param {Number} id
   * @param {Object} data
   * @param {Object} config
   * @returns {Object}
   */
  update(id, data, config = {}) {
    return $axios.put(`${this.resource}/${id}`, data, config)
  },

  /**
   * Delete resource
   *
   * @param {Number} id
   * @param {Object} config
   * @returns {Object}
   */
  delete(id, config = {}) {
    return $axios.delete(`${this.resource}/${id}`, config)
  }
})

/**
 * Binding repository
 *
 * @param {Object} $axios
 * @param {Functiion} createRepository
 * @returns {Object}
 */
const bind = ($axios, createRepository) => {
  const repository = createRepository($axios)
  if (!repository.resource) {
    throw new Error('Missing resource')
  }

  return {
    ...make($axios, repository.resource),
    ...repository
  }
}

export default $axios => ({
  role: bind($axios, RoleRepository),
  user: make($axios, 'user'),
  news: make($axios, 'news'),
  apps: bind($axios, appsRepository),
  surveys: bind($axios, surveysRepository),
  character: bind($axios, characterRepository),
  result: bind($axios, resultRepository),
  cat: bind($axios, catRepository),
  voicy: bind($axios, voicyRepository),
  questions: bind($axios, questionsRepository),
  userAnswers: bind($axios, userAnswersRepository),
  key: bind($axios, keyRepository)
})
