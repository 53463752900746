
export default {
  components: {},

  computed: {
    locale() {
      const locale = this.$i18n.locales.find(
        item => item.code === this.$i18n.locale
      )
      const localeISO = locale ? locale.iso : 'en-US'
      const provider = require(`ant-design-vue/es/locale-provider/${localeISO.replace(
        '-',
        '_'
      )}`)
      return provider.default
    }
  }
}
