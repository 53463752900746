
export default {
  beforeMount() {
    window.removeEventListener('scroll', this.onScroll)
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },

  methods: {
    goToAnchor(anchor) {
      this.$store.dispatch('setVisibleUserOffcanvasMenu', false)
      this.$scrollTo(anchor, 800, { offset: 0 })
    },

    onScroll(e) {
      const elm = document.getElementsByClassName('main-user-header')

      if (window.top.scrollY > 0) {
        elm?.[0]?.classList.add('fixed')
      } else {
        elm?.[0]?.classList.remove('fixed')
      }
    },

    goLink() {
      if (this.$route.query.k) {
        this.$router.push('/?k=' + this.$route.query.k)
      }
    }
  }
}
