export default $axios => ({
  resource: 'character',

  /**
   * Update resource
   *
   * @param {Number} id
   * @param {Object} data
   * @param {Object} config
   * @returns {Object}
   */
  updateWithImage(id, data, config = {}) {
    return $axios.post(`character/${id}`, data, config)
  }
})
