import html2canvas from 'html2canvas'

export default ({ app }, inject) => {
  const html2canvasWithAllowTaint = (element, options = {}) => {
    const mergedOptions = {
      allowTaint: true,
      useCORS: true
    }
    return html2canvas(element, mergedOptions)
  }

  inject('html2canvas', html2canvasWithAllowTaint)
}
