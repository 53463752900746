
export default {
  methods: {
    goToAnchor(anchor) {
      this.$store.dispatch('setVisibleUserOffcanvasMenu', false)
      this.$scrollTo(anchor, 800, { offset: 0 })
    },

    closeOffcanvasMenu() {
      this.$store.dispatch('setVisibleUserOffcanvasMenu', false)
    }
  }
}
